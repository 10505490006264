.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #A9C9FF;
    background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);


}

.form-container {
  width: auto; 
  max-width: 900px;
  margin-top: 100px;
  margin-bottom: 50px;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #FEE140;
  background-image: linear-gradient(90deg, #FEE140 0%, #FA709A 100%);

  /* box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.2); */
  text-align: center;
    
    /* border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; */
}