.course-update {
    margin: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:10;
}


.courses-data {
    margin:20px;
    display: flex;
    gap: 20px;
}